<template>
  <div>
    <div class="row">
      <div class="col-12 text-right">
        <a
          class="btn btn-success"
          href="javascript:void(0)"
          @click.prevent="resetForm"
        > <i /> Reset valeurs</a>
        <a
          class="btn btn-primary"
          href="javascript:void(0)"
          @click.prevent=" showAnimal = !showAnimal"
        > 
          Voir détail
        </a>
      </div>
    </div>
    <div class="row">
      <div :class="showAnimal? 'col-7':'col-12'">
        <form @submit.prevent="updating">
          <div
            class="alert alert-danger"
            v-if="has_error"
          >
            {{ error_msg }}
          </div>
          <input-resultat-analyse
            v-for="(p,i) in filtredParametres"
            :key="i"
            :index="i"
            :parametre="p"
            :type-fiche="type"
            :animal="animal"
          />
          <div class="form-group">
            <label for="commentaire">Commentaire</label>
            <textarea
              class="form-control"
              name="commentaire"
              id="commentaire"
              v-model="commentaire"
            />
          </div>
          <div class="form-group text-right">
            <button
              type="submit"
              class="btn btn-primary"
            >
              Enregistrer
            </button>
          </div>
        </form>
      </div>
      <div
        class="col-5"
        v-if="showAnimal"
      >
        <div class="row">
          <div class="col-12">
            <h4> Resultat</h4>
            <p
              v-for="(r,i) in resultats"
              :key="i"
            >
              {{ r.order }} - {{ r.parametre }} - {{ r.valeur }}
            </p>
          </div>
        </div>
            
        <div
          class="row"
          v-if="showAnimal"
        >
          <div class="col-12">
            <h4> Animal</h4>
            <animal :animal-id="animal" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import inputResultatAnalyse from './inputResultatAnalyse.vue';
import Animal from '../../identification/animal/animalVerticalDetail.vue'
import { UPDATE_RESULTAT_ANALYSE_FICHE } from '../../../graphql/ficheAnalyse';
export default {
    components: {inputResultatAnalyse, Animal},
    props: {
        fiche: {type: String, required: true},
        analyse: {type: String, required: true}
    },
    beforeMount(){
       this.resultatAnalyse = this.resultatAnalyses.find(item => item.analyse === this.analyse)
       if(this.resultatAnalyse !== null && this.resultatAnalyse !== undefined){
        this.setResultat(JSON.parse(this.resultatAnalyse.data))
        this.commentaire = this.resultatAnalyse.commentaire
       }
    },  
    data(){
        return {
            resultatAnalyse: null,
            showAnimal: false,
            commentaire: null,
            has_error: false,
            error_msg: null
        }
    },
    methods: {
        ...mapMutations({
            setResultat: 'analyse/SET_RESULTAT',
            initResultat: 'analyse/INIT_RESULTAT',
            setReset: 'analyse/SET_RESET',
            setLoading: 'SET_LOADING',
            done: 'DONE'
        }),
        resetForm(){
            this.has_error = false
            this.error_msg = null
            this.setLoading(false)
            setTimeout(() => {
                this.setReset(false)
                this.setResultat(this.resultats)
            }, 1000)
        },
        updating(){
            this.setLoading(true)
            let data = {
                analyse: this.analyse,
                data: JSON.stringify(this.resultats),
                commentaire: this.commentaire
            }
            this.$apollo.mutate({
                mutation: UPDATE_RESULTAT_ANALYSE_FICHE,
                variables: {
                    "resultat": {
                        ...data
                    }, 
                    "uid": this.resultatAnalyse.uid
                },
                update: (d) => {
                    console.log(d)
                    console.log(`Resultat analyse fiche ${this.libelle} updated successfully`)
                }
            }).then(() => {
                this.resetForm()
                this.done()
            }).catch((error) => {
                console.error(error)
                this.setLoading(false)
                this.has_error = true
                this.error_msg = error
            })
        }
    },
    computed: {
        ...mapGetters({
            fiches: 'sanitaire/ficheAnalyses',
            typeFiches: 'sanitaire/typeFicheAnalyses',
            parametres: 'sanitaire/ficheParametres',
            analyses: 'sanitaire/analyses',
            resultats: 'analyse/resultats',
            resultatAnalyses: 'analyse/resultatAnalyses',
            loading: 'loading'
        }),
        type(){
            let f = this.fiches.find(item => item.uid === this.fiche)
            if(f) {
                let t = this.typeFiches.find(item => item.uid === f.categorieAnalyse)
                if(t) return t
            }
            return null
        },
        analyseObject(){
            let a = this.analyses.find(item => item.uid === this.analyse)
            if(a) return a
            return null
        },
        animal(){
            return (this.analyseObject !== null)? this.analyseObject.animal : null
        },
        filtredParametres(){
            return [...this.parametres].filter(item => item.fiche.split(',').includes(this.fiche))
        }
    }

}
</script>

<style>

</style>