<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    <detail-sanitaire
      :animal="animal"
      :create-by="createBy"
      :create-at="createAt"
    >
      <template slot="header">
        <h4>{{ header|libelle }}</h4>
      </template>
      <template slot="content">
        <div class="row">
          <div
            class="widget"
            style="width: 100%;"
          >
            <div class="widget-heading">
              <menu-action
                :analyse="analyse"
                :has-fiche="hasFiche"
                :has-fichiers="hasFichiers"
                :has-resultat="hasResultat"
                v-if="hasActions"
              />
            </div>
            <div class="widget-content">
              <div class="row">
                <div class="col-5">
                  <div class="analyse-demande">
                    <h4>Analyse demandée</h4>
                    <div class="row info">
                      <div class="col-12">
                        <h6> Type d'analyse </h6>
                        <p>{{ typeAnalyse|libelle }}</p>
                      </div>
                    </div>
                    <div class="row info">
                      <div class="col-12">
                        <h6> Catégorie d'analyse </h6>
                        <p>{{ categorie|libelle }}</p>
                      </div>
                    </div>
                    <div class="row info">
                      <div class="col-12">
                        <h6> Type de recherche </h6>
                        <p>{{ typeRecherche|libelle }}</p>
                      </div>
                    </div>
                    <div class="row info">
                      <div class="col-12">
                        <h6> Examen </h6>
                        <p>{{ examen|libelle }}</p>
                      </div>
                    </div>

                    <div class="row info">
                      <div class="col-12">
                        <h6> Date de la demande </h6>
                        <p>{{ dateDemande|systemDateTime }}</p>
                      </div>
                    </div>

                    <div class="row info">
                      <div class="col-12">
                        <h6> Information supplémentaire </h6>
                        <p>{{ infoSupplementaire }}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-7">
                  <div class="resultat-analyse">
                    <h4>Resultat d'analyse</h4>
                    <resultat-analyse
                      :analyse="uidAnalyse"
                      :fiche-uid="fiche"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </detail-sanitaire>
    <modal
      id="add-analyse-result"
      :footer="false"
      title="Ajout des fichiers complémentaires au resultat d'analyse"
      size="x-large"
    >
      <add-file-complementaire :analyse="uidAnalyse" />
    </modal>
    
    <modal
      id="add-fiche-valeur"
      :footer="false"
      title="Ajouter les resultats d'analyse"
      size="x-large"
    >
      <add-result-analyse
        :fiche="fiche"
        :analyse="uidAnalyse"
      />
    </modal>

    <modal
      id="update-fiche-valeur"
      :footer="false"
      title="Modification des resultats d'analyse"
      size="x-large"
    >
      <update-result-analyse
        :fiche="fiche"
        :analyse="uidAnalyse"
      />
    </modal>

    <modal
      id="print-fiche-analyse"
      title="Imprimer les resultats d'anayse"
      size="x-large"
    >
      <print-reference-valeur-fiche
        :animal="animal"
        :analyse-uid="uidAnalyse"
        :fiche-uid="fiche"
      />
      <print-resultat-direct-fiche
        :animal="animal"
        :analyse-uid="uidAnalyse"
        :fiche-uid="fiche"
      />
      <print-interpretation-fiche 
        :animal="animal"
        :analyse-uid="uidAnalyse"
        :fiche-uid="fiche"
      />
      <template slot="footer">
        <a
          href="javascript:void(0)"
          class="btn btn-primary"
          @click.prevent="print"
        > Imprimer</a>
      </template>
    </modal>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import { Printd } from 'printd'
import Modal from '../../../components/modal.vue'
import navbar from '../../../components/navbar.vue'
import DetailSanitaire from '../../../components/sanitaire/detailSanitaire.vue'
import resultatAnalyse from '../../../components/sanitaire/analyse/resultatAnalyse.vue'
import addFileComplementaire from '../../../components/sanitaire/analyse/addResultat.vue'
import AddResultAnalyse from '../../../components/sanitaire/analyse/addResultatAnalyse.vue'
import UpdateResultAnalyse from '../../../components/sanitaire/analyse/updateResultatAnalyse.vue'
import PrintReferenceValeurFiche from '../../../components/sanitaire/analyse/fiche/referecenceValeur.vue'
import PrintResultatDirectFiche from '../../../components/sanitaire/analyse/fiche/resultatDirect.vue'
import PrintInterpretationFiche from '../../../components/sanitaire/analyse/fiche/interpretation.vue'
import MenuAction from '../../../components/sanitaire/analyse/detailAction.vue'
export default {
    components: {
      navbar, 
      resultatAnalyse, 
      Modal, 
      DetailSanitaire, 
      addFileComplementaire, 
      AddResultAnalyse, 
      UpdateResultAnalyse,
      PrintReferenceValeurFiche,
      PrintResultatDirectFiche,
      PrintInterpretationFiche,
      MenuAction
    },
    data(){
        return {
          navbarItems: [
            {
                libelle: 'Sanitaire'
            },
            {
                libelle: 'Examen para-clinique',
                route: 'analyses'
            },
            {
              libelle: 'Détail'
            }
          ],
          pageIcon: 'la-flask',
          pageTitle: 'Détail examen para-clinique',
          pageDescription: 'Detail de l\'examen', 
        }
    },
    
    methods: {
      ...mapMutations({
            initResultat: 'analyse/INIT_RESULTAT',
            done: 'DONE'
        }),
        print(){
          console.log(`Printing result of analyse ${this.analyse.uid}`)
          const styles = [
            'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
            'https://unpkg.com/kidlat-css/css/kidlat.css',
            'https://maxst.icons8.com/vue-static/landings/line-awesome/font-awesome-line-awesome/css/all.min.css',
            'https://maxst.icons8.com/vue-static/landings/line-awesome/line-awesome/1.3.0/css/line-awesome.min.css',
            `.animal{ padding: 20px; border: solid 1px rgba(0,0,0,0.17); margin: 10px 0px 20px 0px;}
             .animal label{
                  width: 25%;
              }
              .valeur span{
                width: 60%;
              }
              .valeur small{
                margin-left: 30px;
              }
              .valeur-interpretation{
                min-height: 200px;
              }`
          ]
          const d = new Printd()
          d.print( document.getElementById('resultat') , styles)
          d.onAfterPrint((event) => {
            console.log('After print trigger with event', event)
            this.done()
          })
        }
    },
    computed: {
        ...mapGetters({
            analyses: 'sanitaire/analyses',
            fichiers: 'sanitaire/resultatAnalyses',
            resultats: 'analyse/resultatAnalyses',
            animaux: 'identification/animaux',
            types: 'sanitaire/typeAnalyses',
            categories: 'sanitaire/categorieAnalyses',
            typeRecherches: 'sanitaire/typeRecherches',
            examens: 'sanitaire/examens',
            users: 'auth/users',
            is_super_admin: 'auth/is_super_admin',
            is_admin: 'auth/is_admin',
            is_veterinaire: 'auth/is_veterinaire',
            is_assistant_veterinaire: 'auth/is_assistant_veterinaire',
            is_assistant_veto_major: 'auth/is_assistant_veto_major',
            is_labo_user: 'auth/is_labo_user',
            is_technicien_imagerie: 'auth/is_technicien_imagerie'
        }),
        analyse(){
            return this.analyses.find(item=> item.uid === this.$route.params.uid)
        },
        hasAnalyse(){
            return this.analyse !== undefined && this.analyse !== null
        },
        animal(){
          return  (this.hasAnalyse) ?  this.analyse.animal : null
        },
        createBy(){
            return (this.hasAnalyse)? this.analyse.createBy:null
        },
        createAt(){
            return (this.hasAnalyse)? this.analyse.createdAt:null
        },

        hasFichiers(){
          if(this.hasAnalyse){
            return this.fichiers.some(item => item.analyse === this.analyse.uid)
          }
          return false
        },
        hasResultat(){
          if(this.hasAnalyse){
            return this.resultats.some(item => item.analyse === this.analyse.uid)
          }
          return false
        },
        infoSupplementaire(){
            return (this.hasAnalyse)?  this.analyse.infoSupplementaire : '-'
        },
        typeAnalyse(){
            return (this.hasAnalyse) ? this.types.find(item => item.uid === this.analyse.type) : null
        },
        categorie(){
          return (this.hasAnalyse) ? this.categories.find(item => item.uid === this.analyse.categorie) : null
        },
        
        typeRecherche(){
          return  (this.hasAnalyse) ?  this.typeRecherches.find(item => item.uid === this.analyse.typeRecherche) :  null
        },
        examen(){
          return  this.hasAnalyse ? this.examens.find(item => item.uid === this.analyse.examen) : null
        },
        dateDemande(){
          return this.hasAnalyse ? (this.analyse.operationDate !== null ? this.analyse.operationDate : this.analyse.createAt) : null
        },
        hasFiche(){
          if(this.examen) return this.examen.hasFiche
          if(this.typeRecherche) return this.typeRecherche.hasFiche
          if(this.categorie) return this.categorie.hasFiche
          if(this.typeAnalyse) return this.typeAnalyse.hasFiche
          return null
        },
        fiche(){
          if(this.examen) return this.examen.fiche
          if(this.typeRecherche) return this.typeRecherche.fiche
          if(this.categorie) return this.categorie.fiche
          if(this.typeAnalyse) return this.typeAnalyse.fiche
          return null
        },
        header(){
          if(this.examen) return this.examen
          if(this.typeRecherche) return this.typeRecherche
          if(this.categorie) return this.categorie
          return this.typeAnalyse
        },
        uidAnalyse(){
            return (this.hasAnalyse)? this.analyse.uid : null
        },
        hasActions(){
          return this.is_admin ||
          this.is_labo_user ||
          this.is_technicien_imagerie ||
          this.is_veterinaire
        }
        
    }
}
</script>

<style lang="scss" scopped>
.widget{
  .custom-dropdown-icon {
    position: absolute;
    right: 10px;
  }
}

.analyse-demande{
  padding: 0px 20px;
  border-right: solid 1px rgba($color: #000000, $alpha: 0.05);
  font-size: 12px;
  h4{
    text-transform: uppercase;
    text-align: center;
  }
  h6{
    font-size: 14px;
  }
}
.resultat-analyse{
  h4{
    text-transform: uppercase;
    text-align: center;
  }
}

</style>