<template>
  <div>
    <div
      class="row valeur"
      v-if="isReferenceValeurFiche"
    >
      <div class="col-9">
        {{ parametre |libelle }} <span class="badge badge-info">{{ reference }}</span>
      </div>
      <div class="col-3">
        {{ valeur.valeur }}
      </div>
    </div>
    <div
      class="row"
      v-if="isResultatDirectFiche"
    >
      <div class="col-12">
        <h6>{{ parametre |libelle }}</h6>
      </div>
      <div class="col-12">
        {{ valeur.valeur }}
      </div>
    </div>
    <div
      class="row"
      v-if="isInterpretationFiche"
    >
      <div class="col-4">
        <h6>{{ parametre |libelle }}</h6>
      </div>
      <div class="col-4">
        <label>Loc: </label> {{ valeur.localisation }}
      </div>
      <div class="col-4">
        <label>Car: </label> {{ valeur.caracterisation }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ANALYSE_INTERPRETATION, ANALYSE_REFERENCE_VALEUR, ANALYSE_RESULTAT_DIRECT } from '../../../constants/app'
export default {
    props: {
        valeur: {type: Object, required: true},
        analyse: {type: String, required: true},
        type: {type: String, required: true}
    },
    computed: {
        ...mapGetters({
            parametres: 'sanitaire/ficheParametres',
            references: 'sanitaire/ficheReferences',
            analyses: 'sanitaire/analyses',
            animaux: 'identification/animaux',
            espece: 'identification/especes'
        }),
        isReferenceValeurFiche(){
            if(this.type !== null) return this.type === ANALYSE_REFERENCE_VALEUR
            return false
        },
        isResultatDirectFiche(){
            if(this.type !== null) return this.type === ANALYSE_RESULTAT_DIRECT
            return false
        },
        isInterpretationFiche(){
            if(this.type !== null) return this.type === ANALYSE_INTERPRETATION
            return false
        },
        analyseObject(){
            let a = this.analyses.find(item => item.uid === this.analyse)
            if(a) return a
            return null
        },
        animalObject(){
            if(this.analyseObject !==  null && this.analyseObject !== undefined)
                return this.animaux.find(item => item.uid === this.analyseObject.animal)
            return null
        },
        parametre(){
            let p = this.parametres.find(item => item.uid === this.valeur.parametre)
            if(p) return p
            return null
        },
        reference(){
            if(this.animalObject !== null && this.animalObject !== undefined){
                let r = this.references.find(item => item.parametre === this.parametre.uid && item.espece === this.animalObject.espece)
                if(r) return r.valeur
            }
            return '-'
        }
    }

}
</script>

<style lang="scss" scoped>
.valeur{
    padding: 10px;
    border-bottom: solid 1px rgba($color: #000000, $alpha: 0.05);
    font-size: 12px;
}
</style>