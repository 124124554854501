<template>
  <div>
    <div
      class="alert alert-danger"
      v-if="!hasResultat && !hasFichiers"
    >
      Pas de resultat associé à cette analyse   
    </div>
    <div
      class="result-overview"
      v-if="hasResultat"
    >
      <!-- {{ resultat }} -->
      <div class="row">
        <div class="col-12 text-right">
          <small>{{ resultat.createdAt|systemDateTime }}</small>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <label v-if="isReferenceValeurFiche">Valeurs</label>
          <div
            v-else
            class="m-5"
          />
          <view-valeur-parametre
            v-for="(d,i) in resultatData"
            :key="i"
            :valeur="d"
            :analyse="analyse"
            :type="typeCode"
          />
        </div>
      </div>
      <div
        class="row"
        v-if="isReferenceValeurFiche"
      >
        <div
          class="col-12"
          style="margin-top: 20px;"
        >
          <label>Commentaire</label>
          <p>{{ resultat.commentaire }}</p>
        </div>
      </div>
      <div class="m-5" />
      <div class="row create-by">
        <user-inline :uid="createdBy" />
      </div>
    </div>

    <div
      class="fichier-overview"
      v-if="hasFichiers"
    >
      <!-- {{ fichier complementaire }} -->
      <h6>Fichiers complémentaires</h6>
      <div class="row">
        <div
          class="col-12"
          style="margin-top: 20px;"
        >
          <label>Conclusion</label>
          <p>{{ conclusion }}</p>
        </div>
      </div>
      <div class="d-flex files">
        <a
          target="_blank"
          :href="f.url"
          v-for="(f, i) in files"
          :key="i"
        >{{ f.name }}</a>
      </div>
      <div class="row create-by">
        <user-inline :uid="createFichierBy" />
      </div>
    </div>
    
    
    <!-- <li class="list-group-item d-flex justify-content-between align-items-start">
        <div class="ms-2 me-auto">
          <div class="fw-bold">
            Fichiers
          </div>
          <div class="d-flex files">
            <a
              target="_blank"
              :href="f.url"
              v-for="(f, i) in files"
              :key="i"
            >{{ f.name }}</a>
          </div>
        </div>
      </li> -->
  </div>
</template>

<script>
import {  mapGetters} from 'vuex'
import UserInline from '../../admin/user/userInline.vue'
import viewValeurParametre from './viewValeurParametre.vue'
import { ANALYSE_INTERPRETATION, ANALYSE_REFERENCE_VALEUR, ANALYSE_RESULTAT_DIRECT } from '../../../constants/app'
export default {
    props: {
        analyse: {type: String, required: true},
        ficheUid: {type: String, required: true}
    },
    components: {UserInline, viewValeurParametre},
    computed: {
        ...mapGetters({
            analyses: 'sanitaire/analyses',
            fichiers: 'sanitaire/resultatAnalyses',
            typeFiches: 'sanitaire/typeFicheAnalyses',
            resultats: 'analyse/resultatAnalyses',
            laboUsers: 'auth/users',
            fiches: 'sanitaire/ficheAnalyses',
        }),
        type(){
            let f = this.fiches.find(item => item.uid === this.ficheUid)
            if(f) {
                let t = this.typeFiches.find(item => item.uid === f.categorieAnalyse)
                if(t) return t
            }
            return null
        },
        typeCode(){
          if(this.type !== null) return this.type.code
          return null
        },
        isReferenceValeurFiche(){
            if(this.type !== null) return this.type.code === ANALYSE_REFERENCE_VALEUR
            return false
        },
        isResultatDirectFiche(){
            if(this.type !== null) return this.type.code === ANALYSE_RESULTAT_DIRECT
            return false
        },
        isInterpretationFiche(){
            if(this.type !== null) return this.type.code === ANALYSE_INTERPRETATION
            return false
        },
        selectedAnalyse(){
            return this.analyses.find(item => item.uid === this.analyse)
        },
        resultat(){
            let r = this.resultats.find(item => item.analyse === this.analyse)
            if(r) return r
            return null
        },
        hasResultat(){
            return this.resultat !== null && this.resultat !== undefined
        },
        hasFichiers(){
          return this.files.length > 0
        },
        resultatData(){
          return JSON.parse(this.resultat.data).sort((a,b) => a.order - b.order)
        },
        createdBy(){
          return (this.hasResultat)?  this.resultat.createBy : null
        },
        createdAt(){
            if(this.hasResultat) return this.$options.filters.systemDateTime(this.resultat.createdAt)
            return '-'
        },
        files(){
          let fs = this.fichiers.find(item => item.analyse === this.analyse)
          if(fs) return JSON.parse(fs.files)
          return []
        },
        conclusion(){
          let fs = this.fichiers.find(item => item.analyse === this.analyse)
          if(fs) return fs.conclusion
          return '-'
        },
        createFichierBy(){
          let fs = this.fichiers.find(item => item.analyse === this.analyse)
          if(fs) return fs.createBy
          return null
        }
    }

}
</script>

<style lang="scss" scoped>
.result-overview, .fichier-overview{
  position: relative;
  padding-bottom: 100px;
  .create-by{
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
}
.fichier-overview{
  border-top: solid 1px rgba(0,0,0,0.15);
  padding-top: 20px;
}

.fw-bold{
    margin-bottom: 20px;
    font-weight: bolder;
}
.files{
    a{
        padding: 10px;
        border: solid 1px rgba(0,0,0,0.15);
        margin: 5px;
    }
}
</style>