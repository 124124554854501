<template>
  <base-component
    :animal-uid="animal"
    :date="date"
    v-if="visible"
  >
    <template slot="content">
      <div style="margin: 0px; padding-top: 20px; border: solid 1px rgba(0,0,0,0.17);">
        <div
          class="row"
          style="padding: 20px;margin-bottom: 30px;"
        >
          <div class="col-12">
            <h5 style="padding-bottom: 3px; border-bottom: solid 1px rgba(0,0,0,0.17); display: inline-block; margin-bottom: 10px;">
              Examen demandé:
            </h5>
          </div>
          <div class="col-12"> 
            <h6 style="color:#b50404;">
              {{ header|libelle }}
            </h6>
            <small>
              <span v-if="typeAnalyse !== null"> {{ typeAnalyse |libelle }} </span>  
              <span v-if="categorie !== null"> -  {{ categorie |libelle }} </span>
              <span v-if="typeRecherche !== null"> -  {{ typeRecherche |libelle }} </span>
            </small>
          </div>
        </div>
        <div
          class="row"
          style="padding: 20px;margin-bottom: 30px;"
        >
          <div class="col-12">
            <h5 style="padding-bottom: 3px; border-bottom: solid 1px rgba(0,0,0,0.17); display: inline-block;">
              Résultat:
            </h5>
          </div>
          <div class="col-12">
            {{ valeurs[0].valeur }}
          </div>
        </div>
      </div>
    </template>
  </base-component>
</template>

<script>
import { mapGetters } from 'vuex';
import baseComponent from './base.vue'
import { ANALYSE_RESULTAT_DIRECT } from '../../../../constants/app'
export default {
    components: {baseComponent},
    props: {
      analyseUid: {type: String, required: true},
      animal: {type: String, required: true},
      ficheUid: {type: String, required: true},
    },
    data(){
        return {
        }
    },
    methods: {
        
    },
    computed: {
        ...mapGetters({
            analyses: 'sanitaire/analyses',
            resultats: 'analyse/resultatAnalyses',
            typeFiches: 'sanitaire/typeFicheAnalyses',
            fiches: 'sanitaire/ficheAnalyses',
            types: 'sanitaire/typeAnalyses',
            categories: 'sanitaire/categorieAnalyses',
            typeRecherches: 'sanitaire/typeRecherches',
            examens: 'sanitaire/examens',
        }),
        analyse(){
          return this.analyses.some(item => item.uid === this.analyseUid) ? this.analyses.find(item => item.uid === this.analyseUid) : null
        },
        hasAnalyse(){
          return this.analyse !== null
        },
        typeAnalyse(){
            return (this.hasAnalyse) ? this.types.find(item => item.uid === this.analyse.type) : null
        },
        categorie(){
          return (this.hasAnalyse) ? this.categories.find(item => item.uid === this.analyse.categorie) : null
        },
        
        typeRecherche(){
          return  (this.hasAnalyse) ?  this.typeRecherches.find(item => item.uid === this.analyse.typeRecherche) :  null
        },
        examen(){
          return  this.hasAnalyse ? this.examens.find(item => item.uid === this.analyse.examen) : null
        },
        resultat(){
          return this.resultats.some(item => item.analyse === this.analyseUid) ? this.resultats.find(item => item.analyse === this.analyseUid) : null
        },
        valeurs(){
          return (this.resultat !== null) ? JSON.parse(this.resultat.data) : []
        },
        date(){
          return (this.resultat !== null) ? this.resultat.createdAt : null
        },
        header(){
          if(this.examen) return this.examen
          if(this.typeRecherche) return this.typeRecherche
          if(this.categorie) return this.categorie
          return this.typeAnalyse
        },
        visible(){
          let f = this.fiches.find(item => item.uid === this.ficheUid)
          if(f){
            let t = this.typeFiches.find(item => item.uid === f.categorieAnalyse)
            if(t) return t.code === ANALYSE_RESULTAT_DIRECT
          }
          return false
        }
        
    }

}
</script>

<style lang="scss" scoped>
.resultat-direct{
  
}

</style>