<template>
  <form
    class="form-horizontal"
    @submit.prevent="addingResultat"
  >
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label for="conclusion">Conclusion</label>
          <textarea
            rows="5"
            class="form-control"
            v-model="conclusion"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <load-files @fileAdd="fileAdded" />
    </div>
    <div
      class="row"
      style="margin-top: 20px;"
    >
      <div class="col-12 text-right">
        <button
          type="submit"
          href="javascript::void(0)"
          class="btn btn-secondary bnt-xl"
        > 
          Ajouter les fichiers
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import loadFiles from '../../loadFiles.vue'
import { ADD_RESULTAT_ANALYSE } from '../../../graphql/sanitaire';
export default {
    props: {analyse: {type: String, required: true}},
    components: {loadFiles},
    data(){
        return {
            conclusion: null
        }
    },
    methods: {
        ...mapMutations({
            setAnalyseFiles: 'sanitaire/SET_ANALYSE_FILES',
            addAnalyseFile: 'sanitaire/ADD_ANALYSE_FILE',
            done: 'DONE'
        }),
        fileAdded(payload){
            console.log(payload)
            this.addAnalyseFile(payload)
        },
        addingResultat(){
            let data = {
                files: JSON.stringify(this.analyseFiles),
                analyse: this.analyse,
                conclusion: this.conclusion
            }
            console.log(data)
            this.$apollo.mutate({
                mutation: ADD_RESULTAT_ANALYSE,
                variables: {
                    "resultat":{...data}
                }
            }).then(() => {
                this.done()
                this.$router.push({name: 'analyse_detail', params: {uid: this.analyse}})
            })
        }
    },
    mounted(){
        this.setAnalyseFiles([])
    },
    computed: {
        ...mapGetters({
            token: 'auth/token',
            analyseFiles: 'sanitaire/analyseFiles'
        }),
        
    }
}
</script>

<style>

</style>