<template>
  <div>
    <ul v-if="tempAttachments.length > 0">
      <li
        v-for="tempAttachment in tempAttachments"
        :key="tempAttachment._id"
      >
        <div class="file-details">
          <div class="close">
            <a
              href="javascript:void(0)"
              @click.prevent="deletingFile(tempAttachment)"
            >
              <i class="icofont icofont-close" />
            </a>
          </div>
          <div class="file-name display-flex align-center">
            <p
              class=""
              ref="attachmentTitle"
            >
              {{ tempAttachment.title }}
            </p>
          </div>
          <div class="file-info">
            <span class="uploaded-date"> {{ tempAttachment.size }} bytes </span>
            <span
              v-if="checkProgress(tempAttachment)"
              class="upload-prgress"
            >
              {{ `${tempAttachment.progress} %` }}
            </span>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
  
  <script>
  import { mapGetters, mapMutations} from 'vuex';
  import axios from 'axios'
import { BASE_URL } from '../constants/app';
  export default {
    name: "AttachmentList",
    methods: {
      ...mapMutations({
        deleteTmpFile: 'options/REMOVE_TMP_UPLOAD_FILE'
      }),
      checkProgress(attachment) {
        return attachment.progress === null ? false : true;
      },
      deletingFile(attachment){
        console.log(`Deleting file ${attachment._id}`)
        console.log(attachment)
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`;
        axios.get(`http://${BASE_URL}/files/${attachment.title}/delete`)
        .then(  (response) =>  {
          // handle success
          console.log(response);
          this.deleteTmpFile(attachment)
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .finally(function () {
          // always executed
        });

      }
    },
    props: {
      // tempAttachments: {
      //   type: Array
      // },
      attachments: {
        type: Array
      }
    },
    computed: {
      ...mapGetters({
        tempAttachments: 'options/tmpUploadFiles',
        token: 'auth/token'
      })
    }
  };
  </script>
  
  <style lang="scss" scoped>
  ul {
    display: flex !important;
    flex-wrap: wrap;
    list-style: none;
    padding: 10px 0px;
  }
  li {
    border: 1px solid rgba($color: #000000, $alpha: 0.15);
    margin-bottom: 8px;
    border-radius: 8px;
    margin-right: 8px;
    padding: 0 10px;
  }
  .file-name {
    min-width: 150px;
    font-size: 13px;
  }
  .uploaded-date {
    font-size: 12px;
  }
  .upload-progress {
    font-size: 12px;
  }
  .file-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .file-details{
    padding: 10px 20px;
    position: relative;
    .close{
      position: absolute;
      top: 5px;
      right: -5px;
      font-size: 12px;
      :hover{
        color: red;
        border: solid 1px rgba($color: #000000, $alpha: 0.15)
      }
    }
  }
  </style>
  