<template>
  <base-component
    :animal-uid="animal"
    :date="date"
    v-if="visible"
  >
    <template slot="title">
      <h4 style=" text-align: center; text-decoration: underline; text-transform: uppercase;"> 
        Resultat d'analyse: {{ fiche|libelle }}
      </h4>
    </template>
    <template slot="content">
      <div class="row">
        <div class="col-8">
          <table class="table table-hover table-bordered table-striped">
            <thead>
              <tr>
                <th>Paramètre</th>
                <th>Localisation lésion</th>
                <th>
                  Caracterisation
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(p, i) in filtredParametersByFiche"
                :key="i"
              >
                <td>{{ p.libelle }}</td>
                <td class="valeur">
                  {{ valeur(p, 'localisation') }}
                </td>
                <td>
                  {{ valeur(p, 'caracterisation') }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-4">
          <div class="row">
            <div class="col-12">
              <h4>Synthèse de la description</h4>
              <div class="valeur-interpretation">
                {{ commentaire }}
              </div>
            </div>
            <div class="col-12">
              <h4>Interpretation</h4>
              <div class="valeur-interpretation">
                {{ conclusion }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-12">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <td style="text-align: center; padding-bottom: 50px; min-height: 50px;">
                  Synthèse de la description: 
                  <p class="text-left">
                    
                  </p>
                </td>
              </tr>
              <tr>
                <td style="text-align: center; padding-bottom: 50px; min-height: 50px;">
                  Interpretation: 
                  <p class="text-left">
                    
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> -->
    </template>
  </base-component>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseComponent from './base.vue'
import { ANALYSE_INTERPRETATION} from '../../../../constants/app'
export default {
    components: {BaseComponent},
    props: {
      analyseUid: {type: String, required: true},
      animal: {type: String, required: true},
      ficheUid: {type: String, required: true},
    },
    data(){
        return {
          
        }
    },
    methods: {
        valeur(parametre, type){
          let v = this.valeurs.find(item => item.parametre === parametre.uid)
          if(v) {
            if(type === 'localisation') return v.localisation
            if(type === 'caracterisation') return v.caracterisation
            return '-'
          }
          return '-'
        },
        
        
        filtredParametres(section){
            if(this.selectedObject !== null )
                return [...this.parametres].filter(item => item.fiche.split(',').includes(this.ficheUid) && item.section === section.uid)
            return []
        }
    },
    computed: {
        ...mapGetters({
            analyses: 'sanitaire/analyses',
            especes: 'identification/activeEspeces',
            fiches: 'sanitaire/ficheAnalyses',
            sections : 'sanitaire/ficheSections',
            parametres: 'sanitaire/ficheParametres',
            references: 'sanitaire/ficheReferences',
            resultats: 'analyse/resultatAnalyses',
            animaux: 'identification/animaux',
            typeFiches: 'sanitaire/typeFicheAnalyses',
        }),
        analyse(){
          return this.analyses.some(item => item.uid === this.analyseUid) ? this.analyses.find(item => item.uid === this.analyseUid) : null
        },
        hasAnalyse(){
          return this.analyse !== null
        },
        fiche(){
          return this.fiches.some(item => item.uid === this.ficheUid) ? this.fiches.find(item => item.uid === this.ficheUid) : null
        },
        resultat(){
          return this.resultats.some(item => item.analyse === this.analyseUid) ? this.resultats.find(item => item.analyse === this.analyseUid) : null
        },
        valeurs(){
          return (this.resultat !== null) ? JSON.parse(this.resultat.data) : []
        },
        commentaire(){
          return (this.resultat !== null) ? this.resultat.commentaire : '-'
        },
        conclusion(){
          return (this.resultat !== null) ? this.resultat.info : '-'
        },
        date(){
          return (this.resultat !== null) ? this.resultat.createdAt : null
        },
        filtredSections(){
            if(this.fiche !== null )
                return [...this.sections].filter( item => item.fiche === this.ficheUid)
            return []
        },
        filtredParametersByFiche(){
          if(this.selectedObject !== null ){
            return [...this.parametres].filter(item => item.fiche.split(',').includes(this.ficheUid))
          }
          return []
        },
        isSectionTab(){
          return this.filtredSections.length > 0
        },
        animalObject(){
          return (this.animaux.some(item => item.uid === this.animal))? this.animaux.find(item => item.uid === this.animal) : null
        },
        espece(){
          if(this.animalObject === null) return null
          return (this.especes.some(item => item.uid === this.animalObject.espece))? this.especes.find(item => item.uid === this.animalObject.espece) : null
        },
        visible(){
          if(this.fiche !== null){
            let t = this.typeFiches.find(item => item.uid === this.fiche.categorieAnalyse)
            if(t) return t.code === ANALYSE_INTERPRETATION
          }
          return false
        }
    }

}
</script>

<style lang="scss" scoped>
.valeur-interpretation{
  min-height: 200px;
}
</style>